/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, InfoCell } from '@atoms';
import { Table } from '@molecules';
import { Box, Chip, Link, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ContractSubmission } from '@services/types/contracts';
import { FC, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Axios } from '@helpers';
import { errorMessage } from '@services';
import EditEsignModal from '@organisms/Modals/EsignModal/EditEsignModal';
import { useModalState } from '@hooks';
import { CompanyType } from '@services/types';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';
import { ADMIN_SUBMITTER } from '@constants/esign';

const docusealUrl = process.env.REACT_APP_DOCUSEAL_URL;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export function getEsignSubmissionsColumns(
  onOpen: () => void,
  setSubmissionId: (id: string) => void,
): GridColDef<ContractSubmission>[] {
  function download(dataurl: string, filename: string) {
    Axios.get(`/admin/contracts/download-proxy/${encodeURIComponent(dataurl)}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        errorMessage('This contract is deleted');
      });
  }

  function getStatus(submitters: { status: string; role: string }[]) {
    let status = 'created';
    let color: 'default' | 'warning' | 'success' = 'default';
    const adminIndex = submitters.findIndex((submitter) => submitter.role === 'First Party') ?? 0;
    const userIndex = submitters.findIndex((submitter) => submitter.role === 'Second Party') ?? 1;

    if (submitters[userIndex].status === 'completed') {
      status = 'pending';
      color = 'warning';
      if (submitters[adminIndex].status === 'completed') {
        status = 'completed';
        color = 'success';
      }
    }
    return { status, color };
  }

  function extractUserName(row: ContractSubmission) {
    const { company, username, firstName, lastName } = row.model;
    if (company.type === CompanyType.Business) {
      return username;
    }
    if (company.type === CompanyType.ContentCreator) {
      if (!firstName || !lastName) {
        return username;
      }
      return `${firstName} ${lastName}`.trim();
    }
    return '';
  }

  return [
    {
      field: 'username',
      headerName: 'Username',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { photoUrl } = row.model;
        const displayName = extractUserName(row);
        return <InfoCell name={displayName} avatarUrl={photoUrl || ''} />;
      },
    },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { firstName } = row.model;
        return <Box>{firstName}</Box>;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { lastName } = row.model;
        return <Box>{lastName}</Box>;
      },
    },
    {
      field: 'template',
      headerName: 'Template',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { name, docuSealId } = row.template;
        return (
          <CustomWidthTooltip title={name}>
            <Link
              href={`${docusealUrl}/templates/${docuSealId}`}
              target="_blank"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              onClick={(event) => event.stopPropagation()}
            >
              {name}
            </Link>
          </CustomWidthTooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const data = getStatus(row?.docuSealData?.submitters);

        return <Chip color={data.color} label={data.status} />;
      },
    },
    {
      field: 'id',
      headerName: '',
      flex: 3,
      minWidth: 600,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const { audit_log_url, documents, id, submitters } = row.docuSealData;
        const adminSubmitter = submitters?.find((submitter) => submitter.role === ADMIN_SUBMITTER);
        const slug = adminSubmitter?.slug;
        const { status } = getStatus(submitters);
        const isUpdateButtonEnabled =
          ['pending', 'created'].includes(status) &&
          // row.model.company.type === CompanyType.ContentCreator &&
          row.template.active;

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {documents[0] && (
              <Button
                variant="contained"
                startIcon={<FileDownloadOutlinedIcon />}
                size="small"
                sx={{ height: '32px', fontSize: '14px', padding: '0 16px', minWidth: 190 }}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  download(
                    documents[0].url,
                    `${row.docuSealData.template.name}_${row.model.username}`,
                  );
                }}
              >
                Download Contract
              </Button>
            )}
            {isUpdateButtonEnabled && (
              <Button
                variant="outlined"
                size="small"
                name="btnUpdate"
                sx={{ height: '32px', fontSize: '14px', padding: '0 16px', minWidth: 75 }}
                onClick={() => {
                  onOpen();
                  setSubmissionId(row.id);
                }}
              >
                Update
              </Button>
            )}
            {status === 'pending' && slug && (
              <Link href={`${docusealUrl}/s/${slug}`} target="_blank">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ height: '32px', fontSize: '14px', padding: '0 16px', minWidth: 100 }}
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                  }}
                >
                  Sign now!
                </Button>
              </Link>
            )}
            <Link href={`${docusealUrl}/submissions/${id}`} target="_blank">
              <Button
                variant="outlined"
                size="small"
                sx={{ height: '32px', fontSize: '14px', padding: '0 16px', minWidth: 68 }}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                }}
              >
                View
              </Button>
            </Link>
            {audit_log_url && (
              <Link href={audit_log_url} target="_blank">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ height: '32px', fontSize: '14px', padding: '0 16px', minWidth: 95 }}
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                  }}
                >
                  Audit log
                </Button>
              </Link>
            )}
          </Box>
        );
      },
    },
  ];
}

const ESignSubmissions: FC = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const params = getParams(searchParams) as { page?: number; size?: number };
  // const { data, isLoading } = useQuery<unknown, unknown, ResSubmissions>(['eSignSubmissions'], () =>
  //   Axios.get<ResSubmissions>('/contracts/admin/submissions', {
  //     params: { page: Number(params.page ?? 1) ?? 1, size: Number(params.size ?? 10) ?? 10 },
  //   }).then((res) => res.data),
  // );
  const navigate = useNavigate();
  const [isOpen, , onOpen, onClose] = useModalState(false);
  const [submissionId, setSubmissionId] = useState<string>('');
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const columns = getEsignSubmissionsColumns(onOpen, setSubmissionId);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '32px 24px',
        marginTop: '12px',
        border: '1px solid #e0e0e0',
      }}
    >
      <Table<ContractSubmission & { triggerRefetch: boolean }>
        queryName="/admin/contracts/submissions"
        columns={columns}
        apiRoute="/admin/contracts/submissions"
        refetchQueryName="/admin/contracts/submissions"
        searchPlaceholder="Search for contracts by model username"
        triggerRefetch={triggerRefetch}
        onRowClick={({ modelId, model }, event) => {
          if (!event?.target?.closest('button[name="btnUpdate"]')) {
            if (model.company.type === CompanyType.ContentCreator) {
              navigate(
                `/${ROUTES.ContentCreators}/${model.companyId}/${modelId}/${ROUTES.ModelDetails}`,
              );
            } else if (model.company.type === CompanyType.Business) {
              navigate(
                `/${ROUTES.AssetAccount}/${CompanyType.Business}/${model.companyId}/${modelId}/${ROUTES.AccountDetails}`,
              );
            }
          }
        }}
      />
      <EditEsignModal
        submissionId={submissionId}
        open={isOpen}
        onClose={() => {
          onClose();
          setSubmissionId('');
        }}
        setTriggerRefetch={setTriggerRefetch}
      />
      {/* <DataGrid
        sx={{ flex: 1 }}
        loading={isLoading}
        rows={[]}
        columns={columns}
        initialState={{
          pagination: {
            page: 1,
            pageSize: 10,
          },
        }}
        // pageSizeOptions={[10, 20, 50, 100]}
      /> */}
    </Box>
  );
};

export default ESignSubmissions;
