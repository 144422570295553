import { Modal } from '@atoms';
import { Box, FormHelperText, InputLabel, styled } from '@mui/material';

export const defaultFormControlStyle = {
  backgroundColor: '#fff',
  borderRadius: '10px',
};

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: 'red',
  marginTop: theme.spacing(0.5),
  height: '20px',
  position: 'absolute',
  bottom: '-20px',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(0.5),
}));

export const StyledModal = styled(Modal)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '913px',
  },
}));

export const multiLineEllipsisStyle = {
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'normal',
};

export const defaultStyleIcon = {
  fill: '#A3A7B0',
  '&:hover': {
    fill: '#44AEFB',
  },
};

export const StyledInputLabel = styled(InputLabel)(() => ({
  fontSize: '11px',
  lineHeight: '16px',
}));

const smallInputBase = {
  '& .MuiInputBase-root': {
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '20px',
  },
};

export const dropdownControllerStyles = {
  maxWidth: '131px',

  '& .MuiOutlinedInput-input': {
    paddingRight: '0 !important',
    paddingY: '6px !important',
  },

  '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
    height: '32px',
  },

  '& .MuiFormHelperText-root.Mui-error': {
    marginRight: 0,
  },

  ...smallInputBase,
};

export const textFieldStyles = {
  maxWidth: '368px',

  '& .MuiInputLabel-root': {
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '11px !important',
  },
  '& .MuiInputBase-input': {
    paddingY: '8px !important',
  },

  ...smallInputBase,
};
