import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const DeleteModalIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3571 0H19.6429V7.88889H0V15.7778H55V7.88889H35.3571V0ZM47.1429 71L51.0714 23.6667H3.92857L7.85714 71H47.1429Z"
      fill="#A3A7B0"
      fillOpacity="0.5"
    />
  </BaseIcon>
);
