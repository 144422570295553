import { Button, TextField } from '@atoms';
import { CopyIcon } from '@atoms/Icons/CopyIcon';
import { DeleteIcon } from '@atoms/Icons/DeleteIcon';
import { EditIcon } from '@atoms/Icons/EditIcon';
import { DATE_FORMAT } from '@constants';
import { removedCellClassName } from '@helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table } from '@molecules';
import { Box, Link, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { SocialMediaType, TableSelectType } from '@services';
import { SocialMediaPlatform } from '@services/types/api';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { Control, FieldErrorsImpl, useForm } from 'react-hook-form';
import { EditSocialMediaInput, editSocialMediaInputSchema } from '../api/edit-social-media';
import InteractButton from './interact-button';
import PlatformInfoCell from './platform-info-cell';
import {
  defaultFormControlStyle,
  defaultStyleIcon,
  multiLineEllipsisStyle,
  StyledModal,
} from './styles';

interface IDataSocialMediaUpdateModal {
  listData: SocialMediaType[];
  isOpenDataSocial: boolean;
  onClose: () => void;
}

interface IGetColumnDataSocialMedia {
  control: Control<EditSocialMediaInput>;
  errors: Partial<
    FieldErrorsImpl<{
      fullLink: string;
    }>
  >;
  nickNameEditing: string;
  handleClickEditSocialMedia: (row: SocialMediaType) => void;
}

interface InteractButtonHandlers {
  handleClickUpdate: () => Promise<void>;
  handleClickCancel: () => void;
}

const getColumns = ({
  control,
  errors,
  nickNameEditing,
  handleClickEditSocialMedia,
}: IGetColumnDataSocialMedia): GridColDef<SocialMediaType>[] => {
  return [
    {
      field: 'index',
      headerName: '',
      maxWidth: 60,
      cellClassName: removedCellClassName,
      renderCell: ({ value }) => <Typography>{value + 1}</Typography>,
    },
    {
      field: 'platform',
      headerName: 'Platform',
      minWidth: 133,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.platform,
      // renderCell: ({ row: { platform } }) => (
      //   <PlatformInfoCell platform={platform} />
      // ),
      renderCell: () => <PlatformInfoCell platform={SocialMediaPlatform.FACEBOOK} />,
    },
    {
      field: 'accountUrl',
      headerName: 'Page/Account URL',
      minWidth: 252,
      sortable: false,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.accountUrl,
      renderCell: ({ row: { fullLink, nickname } }) =>
        nickname === nickNameEditing ? (
          <TextField
            sx={{ ...defaultFormControlStyle }}
            name="fullLink"
            control={control}
            defaultValue={fullLink}
            fullWidth
            placeholder="Insert Account URL"
            error={!!errors.fullLink}
            helperText={null}
          />
        ) : (
          <Link
            className="ellipsis"
            sx={{ ...multiLineEllipsisStyle }}
            href={fullLink}
            target="_blank"
          >
            {fullLink}
          </Link>
        ),
    },
    {
      field: 'enforcementDate',
      headerName: 'Enforcement Date',
      minWidth: 208,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.lastEnforcementDate,
      renderCell: () => (
        <Box display="flex" alignItems="center" gap="40px">
          <p>{dayjs('07.08.2024').format(DATE_FORMAT)}</p>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.lastEnforcementDate,
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center" gap="14px">
          <EditIcon
            sx={{
              ...defaultStyleIcon,
            }}
            onClick={() => handleClickEditSocialMedia(row)}
            cursor="pointer"
            width={17}
            height={17}
          />
          <CopyIcon
            sx={{
              ...defaultStyleIcon,
            }}
            cursor="pointer"
            width={12}
            height={15}
          />
          <DeleteIcon
            sx={{
              ...defaultStyleIcon,
            }}
            cursor="pointer"
            width={15}
            height={15}
          />
        </Box>
      ),
    },
  ];
};

const DataSocialMediaUpdateModal = ({
  listData,
  isOpenDataSocial,
  onClose,
}: IDataSocialMediaUpdateModal) => {
  const [rowEditing, setRowEditing] = useState<SocialMediaType | null>(null);

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm<EditSocialMediaInput>({
    resolver: yupResolver(editSocialMediaInputSchema),
    mode: 'onChange',
  });

  const handleClickEditSocialMedia = useCallback(
    (row: SocialMediaType) => {
      if (!rowEditing) {
        setRowEditing(row);
      }
    },
    [rowEditing],
  );

  const columns: GridColDef<SocialMediaType>[] = useMemo(
    () =>
      getColumns({
        control,
        errors,
        nickNameEditing: rowEditing?.nickname || '',
        handleClickEditSocialMedia,
      }),
    [control, errors, handleClickEditSocialMedia, rowEditing?.nickname],
  );

  const handleClickUpdate = async () => {
    const isValid = await trigger();
    if (Object.keys(errors).length !== 0 || !isValid) return;
    // if (!assetId) {
    //   errorMessage('Model could not be found');
    //   return;
    // }
    // const payload = {
    //   ...getValues(),
    //   ids: idSelected,
    // };
    // editMultipleSocialMediaLinksMutation.mutate({ data: payload, modelId: assetId });
  };

  const handleClickCancel = () => {
    setRowEditing(null);
    onClose();
  };

  const renderInteractButtonList = ({
    handleClickUpdate,
    handleClickCancel,
  }: InteractButtonHandlers) => [
    {
      text: 'Cancel',
      sx: {
        color: '#A3A7B0',
        '&:hover': {
          opacity: 0.8,
        },
      },
      variant: 'text',
      handleClick: handleClickCancel,
    },
    {
      text: 'Update',
      sx: {
        color: '#fff',
        backgroundColor: '#44AEFB',
        '&:hover': {
          backgroundColor: '#44AEFB',
          opacity: 0.8,
        },
      },
      variant: 'contained',
      handleClick: handleClickUpdate,
    },
  ];

  const handleCopyAllLinks = () => {};

  const dataTable = useMemo(() => {
    return listData.map((ele, index) => {
      return {
        ...ele,
        index,
        id: `${ele.nickname}-${ele.fullLink}`,
      };
    });
  }, [listData]);

  return (
    <StyledModal maxWidth={false} fullWidth onClose={handleClickCancel} open={isOpenDataSocial}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: '24px',
            lineHeight: '20px',
            color: '#464F60',
          }}
        >
          Data Social Media Update
        </Typography>
        <Button
          variant="outlined"
          color="black"
          onClick={handleCopyAllLinks}
          sx={{
            width: 'fit-content',
            paddingX: '27px',
            borderRadius: '10px',
          }}
        >
          <Box component="div" display="flex" gap={1.25} alignItems="center">
            <CopyIcon width={12} height={15} fill="#1D1D1D" />
            <Typography component="span" color="text.primary" variant="body2">
              Copy all links
            </Typography>
          </Box>
        </Button>
      </Box>
      <Table<SocialMediaType>
        dataTableWithoutCallingAPI={dataTable}
        columns={columns}
        selectedRowType={TableSelectType.SocialMediaLink}
        withoutSearch
        checkHidePagination
        customSxForTable={{
          '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
        }}
      />
      <Box display="flex" justifyContent="end" gap="14px">
        {renderInteractButtonList({ handleClickUpdate, handleClickCancel }).map(
          ({ text, sx, variant, handleClick }) => (
            <InteractButton
              onClick={handleClick}
              key={text}
              sx={sx}
              text={text}
              variant={variant as 'text' | 'outlined' | 'contained'}
              // disabled={editMultipleSocialMediaLinksMutation.isLoading}
            />
          ),
        )}
      </Box>
    </StyledModal>
  );
};

export default React.memo(DataSocialMediaUpdateModal);
