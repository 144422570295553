import { Table, TableCell, TableContainer, TableHead } from '@mui/material';
import styled from 'styled-components';

export const NotificationTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const NotificationTableCell = styled(TableCell)`
  border-bottom: solid 1px #e0e0e0;
  text-align: center;
`;

export const NotificationTableContainer = styled(TableContainer)`
  width: min(900px, 100%) !important;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
`;

export const NotificationTable = styled(Table)`
  border-collapse: collapse;
  background: #ffffff;
  & td,
  & th {
    color: inherit;
    vertical-align: middle;
    text-align: center;
    width: 32%;
  }
`;
