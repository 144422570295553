import { Box } from '@mui/material';
import { BillingFrequencyEnum } from '@services';

export default function BillingFrequency({
  billingFrequency,
}: {
  billingFrequency: BillingFrequencyEnum | null;
}) {
  const getLabel = () => {
    switch (billingFrequency) {
      case BillingFrequencyEnum.Month:
        return 'Monthly';
      case BillingFrequencyEnum.Year:
        return 'Yearly';
      case BillingFrequencyEnum.Day:
        return 'Daily';
      default:
        return null;
    }
  };
  return <Box sx={{ fontWeight: 600 }}>{getLabel()}</Box>;
}
