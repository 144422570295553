import { Box, Skeleton, Switch, TableBody, TableRow, Typography } from '@mui/material';
import {
  NotificationTable,
  NotificationTableCell,
  NotificationTableContainer,
  NotificationTableHead,
} from './styles';
import { Axios } from '@helpers';
import { useMutation, useQuery } from 'react-query';
import { NotificationSettingsResponseType } from '@services';
import { toast } from 'react-toastify';
import { useRef } from 'react';

type SettingKey = keyof NotificationSettingsResponseType['email'];

const allowedSettingKeys: SettingKey[] = ['admin'];

const mapKeyToLabel = (key: SettingKey) => {
  switch (key) {
    case 'admin':
      return 'System Notifications';
    default:
      return '';
  }
};

const CellSkeleton = () => {
  return (
    <Typography component="div" variant={'h3'} sx={{ maxWidth: 200, mx: 'auto' }}>
      <Skeleton animation="wave" sx={{ height: '38px' }} />
    </Typography>
  );
};

const Loading = () => (
  <TableBody>
    {Array(1)
      .fill(null)
      .map((_, i) => {
        return (
          <TableRow key={`table-skeleton-${i}`}>
            <NotificationTableCell>
              <CellSkeleton />
            </NotificationTableCell>
            <NotificationTableCell>
              <CellSkeleton />
            </NotificationTableCell>
          </TableRow>
        );
      })}
  </TableBody>
);

export const NotificationSettingsTable = () => {
  const toastId = useRef<string>('');
  const { data, isLoading, refetch, isRefetching } = useQuery<
    void,
    void,
    NotificationSettingsResponseType
  >([], () => Axios.get(`/user/notifications/settings`).then((res) => res.data));
  const { mutate: updateNotificationSettings, isLoading: updateLoading } = useMutation<
    unknown,
    unknown,
    unknown
  >((data) => Axios.put('/user/notifications/settings', data), {
    onSuccess: () => {
      refetch().then(() => {
        toastId.current &&
          toast.update(toastId.current, {
            render: 'Update successfully!',
            type: 'success',
            isLoading: false,
            autoClose: 2500,
          });
      });
    },
    onError: () => {
      toast.dismiss(toastId.current);
    },
  });
  const disabledSwitch = isRefetching || updateLoading;

  const openLoadingToast = () => {
    toastId.current = toast.loading('Updading...') as string;
  };

  const handleChange = (key: string, checked: boolean, id: string) => {
    updateNotificationSettings({ [key]: checked, id });
    openLoadingToast();
  };

  return (
    <Box sx={{ mt: '32px' }}>
      <NotificationTableContainer>
        <NotificationTable>
          <NotificationTableHead>
            <TableRow>
              <NotificationTableCell>Notify me when...</NotificationTableCell>
              <NotificationTableCell>Email</NotificationTableCell>
            </TableRow>
          </NotificationTableHead>
          {isLoading ? (
            // Initial loading
            <Loading />
          ) : data ? (
            // Notification settings
            <TableBody>
              {(Object.keys(data?.email ?? {}) as SettingKey[])
                ?.filter((key) => allowedSettingKeys.includes(key))
                .map((x) => {
                  return (
                    <TableRow key={x}>
                      <NotificationTableCell>{mapKeyToLabel(x)}</NotificationTableCell>
                      <NotificationTableCell>
                        <Switch
                          disabled={disabledSwitch}
                          onChange={(_, checked) => {
                            data?.email?.id && handleChange(x, checked, data?.email?.id);
                          }}
                          checked={!!data?.email?.[x]}
                        />
                      </NotificationTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            // Empty data
            <TableBody>
              <TableRow sx={{ textAlign: 'center' }}>
                <NotificationTableCell colSpan={12} sx={{ textAlign: 'center' }}>
                  <Box sx={{ display: 'grid', placeContent: 'center', minHeight: 100 }}>
                    No data
                  </Box>
                </NotificationTableCell>
              </TableRow>
            </TableBody>
          )}
        </NotificationTable>
      </NotificationTableContainer>
    </Box>
  );
};
