import React, { SyntheticEvent, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';

import { Box, Typography, Divider, Button } from '@mui/material';
import { UploadDocumentTable } from '@organisms';
import { FileItem } from '@atoms';
import { useMutation } from 'react-query';
import { successMessage } from '@services';
import { Axios, queryClient } from '@helpers';
import { useParams } from 'react-router-dom';
import { CustomContractSubmission } from '@services/types/contracts';
import { getEsignSubmissionsColumns } from '@pages/ESign/ESignSubmissions';
import { Table } from '@molecules';
import { useModalState } from '@hooks';
import EditEsignModal from '@organisms/Modals/EsignModal/EditEsignModal';

export const UploadDocument: React.FC = () => {
  const { assetId } = useParams();
  const [files, setFiles] = useState<File[] | null>(null);
  const [submissionId, setSubmissionId] = useState<string>('');
  const [isOpen, , onOpen, onClose] = useModalState(false);
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const columns = getEsignSubmissionsColumns(onOpen, setSubmissionId);

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    const filesArray = Array.from(input.files);
    const validFiles = filesArray.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length !== filesArray.length) {
      return;
    }
    setFiles(validFiles);
  };

  const removeFile = (e: React.SyntheticEvent, fileIndex?: number) => {
    if (files?.filter((file: File, index: number) => index !== fileIndex).length === 0)
      setFiles(null);
    else setFiles(files?.filter((file: File, index: number) => index !== fileIndex) || null);
    e.stopPropagation();
  };

  const { mutate: uploadDocuments } = useMutation<void, void, File[]>(
    (filesData: File[]) => {
      const formData = new FormData();
      for (let index = 0; index < filesData.length; index += 1) {
        formData.append('files', filesData[index]);
      }
      return Axios.post(`admin/models/${assetId}/model-documents`, formData);
    },
    {
      onSuccess: () => {
        successMessage('documents have been uploaded!');
        queryClient.refetchQueries(`upload-documents-${assetId}`);
        setFiles(null);
      },
    },
  );

  return (
    <>
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb="8px">
            Esign documents
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below is list esign documents of this content creator.
          </Typography>
        </Box>
      </Box>
      <Table<CustomContractSubmission>
        queryName="/admin/contracts/submissions"
        columns={columns}
        apiRoute="/admin/contracts/submissions"
        refetchQueryName="/admin/contracts/submissions"
        withoutSearch
        checkHidePagination={true}
        modelId={assetId}
        triggerRefetch={triggerRefetch}
        customHeight
      />
      <EditEsignModal
        submissionId={submissionId}
        open={isOpen}
        onClose={() => {
          onClose();
          setSubmissionId('');
        }}
        setTriggerRefetch={setTriggerRefetch}
      />
      <Divider sx={{ mt: '40px', mb: '60px' }} />
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box>
          <Typography variant="h5" mb="8px">
            Documents
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Below is list documents of this content creator.
          </Typography>
        </Box>
        <Box display="flex" gap="9px" justifyContent="flex-end">
          {files && files?.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                if (files) uploadDocuments(files);
              }}
            >
              Submit
            </Button>
          )}
          <Button variant="outlined" component="label" color="black" startIcon={<UploadIcon />}>
            Upload documents
            <input
              type="file"
              accept="application/pdf,image/jpeg,image/png,image/gif,image/webp"
              onChange={handleOnChange}
              hidden
              multiple
            />
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: '16px' }} />
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', padding: '16px 0' }}>
        {files?.map((item, index: number) => (
          <FileItem name={item?.name} key={item?.name} onRemove={removeFile} fileIndex={index} />
        ))}
      </Box>
      <UploadDocumentTable />
    </>
  );
};
