import { useMutation, useQueryClient } from 'react-query';
import { Axios, MutationConfig } from '@helpers';
import { getSocialMediaLinksQueryOptions } from './get-social-links';

interface DeleteSocialMediaLinkParams {
  modelId: string;
  socialMediaLinkIds: string[];
}

export const deleteSocialMediaLink = async ({
  modelId,
  socialMediaLinkIds,
}: DeleteSocialMediaLinkParams) => {
  const response = await Axios.delete(`/admin/models/${modelId}/social-media-links`, {
    data: { socialMediaLinkIds },
  });
  return response.data;
};

type UseDeleteSocialMediaLinksOptions = {
  mutationConfig?: MutationConfig<typeof deleteSocialMediaLink>;
};

export const useDeleteSocialMediaLinks = ({
  mutationConfig,
}: UseDeleteSocialMediaLinksOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getSocialMediaLinksQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: deleteSocialMediaLink,
  });
};
