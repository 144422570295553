import { Button } from '@atoms';
import AddIcon from '@mui/icons-material/Add';

const UploadButton = () => {
  return (
    <Button sx={{ paddingX: '20px' }} variant="outlined" color="black" startIcon={<AddIcon />}>
      Upload
    </Button>
  );
};
export default UploadButton;
