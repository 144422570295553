import { FC, ReactElement } from 'react';
import {
  Typography,
  Box,
  IconButton,
  DialogProps as MuiModalProps,
  Dialog as MuiModal,
  DialogContent,
} from '@mui/material';
import { CloseIcon } from '@atoms/Icons/CloseIcon';

interface ModalProps extends MuiModalProps {
  onClose: () => void;
  title?: string;
  width?: string;
  customTitleHeader?: ReactElement;
}

const ModalV2: FC<ModalProps> = ({ children, onClose, title, customTitleHeader, ...props }) => (
  <MuiModal
    onClose={onClose}
    keepMounted={false}
    {...props}
    fullWidth
    scroll="body"
    PaperProps={{
      sx: {
        borderRadius: '24px',
        boxShadow: '0px 2px 10px 0px #00000040',
      },
    }}
  >
    <DialogContent sx={{ p: 0 }}>
      <Box py="26px" px="34px" height="100%" width="100%">
        <Box width="100%" textAlign="right">
          <IconButton onClick={onClose}>
            <CloseIcon width={10} height={10} />
          </IconButton>
        </Box>
        {customTitleHeader ? (
          customTitleHeader
        ) : (
          <Typography
            variant="h5"
            sx={{
              marginTop: '8px',
              color: '#464F60',
            }}
          >
            {title}
          </Typography>
        )}

        {children}
      </Box>
    </DialogContent>
  </MuiModal>
);

export default ModalV2;
