import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const UserIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path
      d="M12.0101 11.4802C14.4567 11.4802 16.4401 9.49673 16.4401 7.05011C16.4401 4.60349 14.4567 2.62012 12.0101 2.62012C9.56346 2.62012 7.58008 4.60349 7.58008 7.05011C7.58008 9.49673 9.56346 11.4802 12.0101 11.4802Z"
      stroke="#1D1D1D"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.2706 21.3799C21.2706 21.3799 20.7806 15.3799 11.9506 15.3799C3.12062 15.3799 2.64062 21.3799 2.64062 21.3799H21.2706Z"
      stroke="#1D1D1D"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </BaseIcon>
);
