import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const CopyIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M3.62088 0.234375H11.3987V11.1233H3.62088V0.234375Z" />
    <path d="M0.509766 3.34549H2.06532V12.6788H8.28754V14.2344H0.509766V3.34549Z" />
  </BaseIcon>
);
