import { Button, TextField } from '@atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useForm } from 'react-hook-form';
// import { useMutation } from 'react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import {
  CaseState,
  caseStateMapper,
  ComplianceStatus,
  platformMapper,
  PostType,
  postTypeMapper,
  SocialMediaCategory,
  socialMediaCategoryMapper,
  SocialMediaPlatform,
} from '@services/types/api';

import ModalV2 from '@atoms/ModalV2';
import { errorMessage, successMessage } from '@services';
import { useParams } from 'react-router-dom';
import {
  CreateSocialMediaLinkInput,
  createSocialMediaLinkInputSchema,
  useCreateSocialMediaLinks,
} from '../api/create-social-links';
import DropdownController from './dropdown-controller';
import {
  defaultFormControlStyle,
  dropdownControllerStyles,
  StyledInputLabel,
  textFieldStyles,
} from './styles';

const buttonStylesModal = {
  width: 116,
  height: 40,
};

const AddSocialMediaLink = () => {
  const [open, setOpen] = React.useState(false);
  const createSocialMediaLinksMutation = useCreateSocialMediaLinks({
    mutationConfig: {
      onSuccess: (data) => {
        if (!data.data?.savedLinks?.length && data.data?.duplicatedLinksCount) {
          errorMessage('Duplicate Social Media Link');
        } else {
          successMessage('Social Media Link has been added!');
          onClose();
        }
      },
      onError: () => {
        errorMessage('Failed to add Social Media Link');
      },
    },
  });

  const { assetId } = useParams();

  const onSubmit = (data: CreateSocialMediaLinkInput) => {
    const payload = {
      ...data,
      // TODO: compliance status is not in the form and should be added
      complianceStatus: ComplianceStatus.Compliant,
      // TODO: enforcement Date is not in the form and should be added
      lastEnforcementDate: new Date().toISOString(),
    };
    if (!assetId) {
      errorMessage('Model could not be found');
      return;
    }
    createSocialMediaLinksMutation.mutate({ data: { links: [payload] }, modelId: assetId });
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateSocialMediaLinkInput>({
    resolver: yupResolver(createSocialMediaLinkInputSchema),
    mode: 'onChange',
    defaultValues: {
      platform: SocialMediaPlatform.DAILYMOTION,
      accountUrl: '',
      mainUrl: '',
      accountName: '',
      category: SocialMediaCategory.ClientOwned,
      postType: PostType.Page,
      caseState: CaseState.Open,
    },
  });

  const onClose = () => {
    reset();
    setOpen(false);
  };
  const platformOptions = Object.values(SocialMediaPlatform);
  const categoryOptions = Object.values(SocialMediaCategory);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ paddingX: '30px' }}
        variant="outlined"
        color="black"
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <ModalV2
        title="Add New Results"
        onClose={onClose}
        open={open}
        maxWidth={false}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '658px',
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, my: 2 }}>
            <Box sx={{ flex: 2.5, display: 'flex', flexDirection: 'column', gap: 1.75 }}>
              {/* Platform */}
              <Box>
                <StyledInputLabel>Platform</StyledInputLabel>
                <DropdownController
                  name="platform"
                  sx={{ ...defaultFormControlStyle, ...dropdownControllerStyles }}
                  control={control}
                  options={platformOptions}
                  getOptionLabel={(option) =>
                    platformMapper[option as SocialMediaPlatform] || (option as string)
                  }
                  errors={errors.platform}
                  heplerText={errors.platform?.message}
                />
              </Box>

              {/* Page/Account URL */}
              <TextField
                name="accountUrl"
                control={control}
                defaultValue=""
                fullWidth
                label="Page/Account URL"
                placeholder="Insert URL Here"
                sx={{
                  ...textFieldStyles,
                }}
              />

              {/* Main URL */}
              <TextField
                name="mainUrl"
                control={control}
                defaultValue=""
                fullWidth
                label="Main URL"
                placeholder="Insert URL Here"
                sx={{
                  ...textFieldStyles,
                }}
              />

              {/* Account Name */}
              <TextField
                name="accountName"
                control={control}
                defaultValue=""
                fullWidth
                label="Account Name"
                placeholder="Insert Name"
                sx={{
                  ...textFieldStyles,
                }}
              />
            </Box>

            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1.75 }}>
              {/* Category */}
              <Box>
                <StyledInputLabel id="category-label">Category</StyledInputLabel>
                <DropdownController
                  name="category"
                  sx={{ ...defaultFormControlStyle, ...dropdownControllerStyles }}
                  control={control}
                  options={categoryOptions}
                  getOptionLabel={(option) =>
                    socialMediaCategoryMapper[option as SocialMediaCategory] || (option as string)
                  }
                  errors={errors.category}
                  heplerText={errors.category?.message}
                />
              </Box>

              {/* Post Type */}
              <Box>
                <StyledInputLabel id="case-type-label">Case Type</StyledInputLabel>
                <DropdownController
                  name="postType"
                  sx={{ ...defaultFormControlStyle, ...dropdownControllerStyles }}
                  control={control}
                  options={Object.values(PostType)}
                  getOptionLabel={(option) =>
                    postTypeMapper[option as PostType] || (option as string)
                  }
                  errors={errors.postType}
                  heplerText={errors.postType?.message}
                />
              </Box>
              {/* Case State */}
              <Box>
                <StyledInputLabel id="case-state-label">Case State</StyledInputLabel>
                <DropdownController
                  name="caseState"
                  sx={{ ...defaultFormControlStyle, ...dropdownControllerStyles }}
                  control={control}
                  options={Object.values(CaseState)}
                  getOptionLabel={(option) =>
                    caseStateMapper[option as CaseState] || (option as string)
                  }
                  errors={errors.caseState}
                  heplerText={errors.caseState?.message}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4.75}>
            <Button
              sx={{ ...buttonStylesModal }}
              onClick={onClose}
              type="button"
              variant="text"
              color="secondary"
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={createSocialMediaLinksMutation.isLoading}
              variant="contained"
              color="primary"
              sx={{ ...buttonStylesModal }}
            >
              Add
            </LoadingButton>
          </Box>
        </form>
      </ModalV2>
    </>
  );
};
export default AddSocialMediaLink;
