import { removedCellClassName } from '@helpers';
import { SearchOptionsCheckbox } from '@molecules/Filters/SearchOptionsCheckbox';
import { Table } from '@molecules/Table';
import { renderDateFilter } from '@molecules/Table/filters';
import { Box, Checkbox, Grid, Link, SelectChangeEvent, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { FilterItem, TableSelectType } from '@services/types';
import {
  CaseState,
  caseStateMapper,
  ComplianceStatus as ComplianceStatusEnum,
  complianceStatusMapper,
  PostType,
  postTypeMapper,
  SocialMediaCategory,
  socialMediaCategoryMapper,
  SocialMediaLink,
} from '@services/types/api';
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { CaseStateFilter } from './case-state-filter';
// import EnforceButton from './enforce-button';
// import ScreenshotButton from './screenshot-button';
import { DATE_FORMAT } from '@constants/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { Control, FieldErrorsImpl, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  EditSocialMediaLinkInput,
  editSocialMediaLinkInputSchema,
  useEditSocialMediaLinks,
  // useEditSocialMediaLinks,
} from '../api/edit-social-links';
import AddButton from './add-social-media-link';
import CaseStateCell from './case-state-cell';
import ComplianceStatus from './compliance-status';
import PlatformInfoCell from './platform-info-cell';
import './styles.css';
import UploadButton from './upload-button';
import { TextField } from '@atoms';
import DropdownController from './dropdown-controller';
import InteractButton from './interact-button';
import { errorMessage, successMessage } from '@services';
import { ConfirmModal } from '@organisms';
import { useConfirmationModal } from '@hooks';
import { DeleteIcon } from '@atoms/Icons/DeleteIcon';
import { EditIcon } from '@atoms/Icons/EditIcon';
import { HistoryIcon } from '@atoms/Icons/HistoryIcon';
import { defaultFormControlStyle, defaultStyleIcon, multiLineEllipsisStyle } from './styles';
import { categoryOptions } from './constants';
import EditButton from './edit-multiple-social-link';
import isEqual from 'lodash.isequal';
import { useDeleteSocialMediaLinks } from '../api/delete-social-links';
import { DeleteModalIcon } from '@atoms/Icons/DeleteModalIcon';
import { LoadingButton } from '@mui/lab';
// import { errorMessage, successMessage } from '@services';

interface InteractButtonHandlers {
  handleClickSave: () => void;
  handleClickCancel: () => void;
  handleClickDelete: () => void;
}

export interface IGetColumnData {
  categoryOptions: SocialMediaCategory[];
  control: Control<EditSocialMediaLinkInput>;
  errors: Partial<
    FieldErrorsImpl<{
      accountName: string;
      category: string;
      postType: string;
      caseState: string;
      complianceStatus: string;
    }>
  >;
  idEditing: string | undefined;
  handleCheckSelectedRow: (id: string) => boolean;
  handleClickEditSingleRow: (row: SocialMediaLink) => void;
}

const CATEGORY_MIXED = 'mixed';

const options = [
  { value: 'all', label: 'All' },
  { label: 'Category', value: 'category' },
  { label: 'Case Type', value: 'case_type' },
  { label: 'Status', value: 'status' },
  { label: 'Priority', value: 'priority' },
];

const FILTERS_LIST: FilterItem[] = [
  {
    name: 'Status',
    key: 'status',
    getFilter: (onChange, onClose, setFilters, filter): React.ReactNode => (
      <CaseStateFilter
        key="status"
        name="status"
        value={filter.status}
        onChange={(event: SelectChangeEvent<unknown>) =>
          setFilters({ ...filter, status: event.target.value as string })
        }
        onClose={onClose}
      />
    ),
  },
  {
    name: 'Period',
    key: 'lastModified',
    resetFields: ['updatedStartDate', 'updatedEndDate'],
    getFilter: renderDateFilter({
      label: 'Period',
      key: 'lastModified',
      resetFields: ['updatedStartDate', 'updatedEndDate'],
    }),
  },
  {
    name: 'Search filter',
    key: 'searchFilter',
    getFilter: (onChange, onClose, setFilters, filter): React.ReactNode => (
      <SearchOptionsCheckbox
        key="isOnlyOneSpaceSearch"
        name="isOnlyOneSpaceSearch"
        control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
        label="Search with one space"
        value={filter.isOnlyOneSpaceSearch}
        onChange={(event: SyntheticEvent<unknown>, checked: boolean) => {
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(checked) });
        }}
        onClose={() => {
          onClose();
          setFilters({ ...filter, isOnlyOneSpaceSearch: String(false) });
        }}
      />
    ),
  },
];

const renderInteractButtonList = ({
  handleClickSave,
  handleClickCancel,
  handleClickDelete,
}: InteractButtonHandlers) => [
  {
    text: 'Save',
    sx: {
      color: '#fff',
      backgroundColor: '#9DD049',
      '&:hover': {
        backgroundColor: '#9DD049',
        opacity: 0.8,
      },
    },
    variant: 'contained',
    handleClick: handleClickSave,
  },
  {
    text: 'Cancel',
    sx: {
      color: '#A3A7B0',
      border: '1px solid #A3A7B0',
      '&:hover': {
        border: '1px solid #A3A7B0',
        opacity: 0.8,
      },
    },
    variant: 'outlined',
    handleClick: handleClickCancel,
  },
  {
    text: 'Delete',
    sx: {
      color: '#fff',
      backgroundColor: '#FF4848',
      '&:hover': {
        backgroundColor: '#b23232',
      },
    },
    variant: 'contained',
    handleClick: handleClickDelete,
  },
];

const getColumns = ({
  categoryOptions,
  control,
  errors,
  idEditing,
  handleCheckSelectedRow,
  handleClickEditSingleRow,
}: IGetColumnData): GridColDef<SocialMediaLink>[] => {
  return [
    {
      field: 'platform',
      headerName: 'Platform',
      minWidth: 133,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.platform,
      renderCell: ({ row: { platform, detectionDate } }) => (
        <PlatformInfoCell platform={platform} date={detectionDate} />
      ),
    },
    {
      field: 'accountUrl',
      headerName: 'Page/Account URL',
      minWidth: 168,
      sortable: false,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.accountUrl,
      renderCell: ({ row: { accountUrl } }) => (
        <Link
          className="ellipsis"
          sx={{ ...multiLineEllipsisStyle }}
          href={accountUrl}
          target="_blank"
        >
          {accountUrl}
        </Link>
      ),
    },
    {
      field: 'mainUrl',
      headerName: 'Main URL',
      minWidth: 106,
      sortable: false,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.mainUrl,
      renderCell: ({ row: { mainUrl } }) => (
        <Link
          className="ellipsis"
          href={mainUrl}
          target="_blank"
          sx={{ ...multiLineEllipsisStyle }}
        >
          {mainUrl}
        </Link>
      ),
    },
    {
      field: 'accountName',
      headerName: 'Account Name',
      minWidth: 149,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.accountName,
      renderCell: ({ row: { accountName, id } }) => (
        <>
          {id === idEditing ? (
            <TextField
              sx={{ ...defaultFormControlStyle }}
              name="accountName"
              control={control}
              defaultValue={accountName}
              fullWidth
              placeholder="Insert Name"
              error={!!errors.accountName}
              helperText={null}
            />
          ) : (
            <Box sx={{ ...multiLineEllipsisStyle }}>{accountName}</Box>
          )}
        </>
      ),
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 133,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.category,
      renderCell: ({ row: { category, id } }) => (
        <>
          {id === idEditing ? (
            <DropdownController
              name="category"
              sx={{ ...defaultFormControlStyle }}
              control={control}
              defaultValue={category}
              options={categoryOptions}
              getOptionLabel={(option) =>
                socialMediaCategoryMapper[option as SocialMediaCategory] || (option as string)
              }
              errors={errors.category}
            />
          ) : (
            <p>{socialMediaCategoryMapper[category]}</p>
          )}
        </>
      ),
    },
    {
      field: 'postType',
      headerName: 'Post Type',
      minWidth: 94,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.postType,
      renderCell: ({ row: { postType, id } }) => (
        <>
          {id === idEditing ? (
            <DropdownController
              name="postType"
              sx={{ ...defaultFormControlStyle }}
              control={control}
              defaultValue={postType}
              options={Object.values(PostType)}
              getOptionLabel={(option) => postTypeMapper[option as PostType] || (option as string)}
              errors={errors.postType}
            />
          ) : (
            <p>{postTypeMapper[postType]}</p>
          )}
        </>
      ),
    },
    {
      field: 'caseState',
      headerName: 'Case State',
      minWidth: 126,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.caseState,
      renderCell: ({ row: { caseState, id } }) => (
        <>
          {id === idEditing ? (
            <DropdownController
              name="caseState"
              sx={{ ...defaultFormControlStyle }}
              control={control}
              defaultValue={caseState}
              options={Object.values(CaseState)}
              getOptionLabel={(option) =>
                caseStateMapper[option as CaseState] || (option as string)
              }
              errors={errors.caseState}
            />
          ) : (
            <CaseStateCell state={caseState} />
          )}
        </>
      ),
    },
    {
      field: 'complianceStatus',
      headerName: 'Compliance Status',
      minWidth: 181,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.complianceStatus,
      renderCell: ({ row: { complianceStatus, id } }) => (
        <>
          {id === idEditing ? (
            <DropdownController
              name="complianceStatus"
              sx={{ ...defaultFormControlStyle }}
              control={control}
              defaultValue={complianceStatus}
              options={Object.values(ComplianceStatusEnum)}
              getOptionLabel={(option) =>
                complianceStatusMapper[option as ComplianceStatusEnum] || (option as string)
              }
              errors={errors.complianceStatus}
            />
          ) : (
            <ComplianceStatus status={complianceStatus} />
          )}
        </>
      ),
    },
    {
      field: 'enforcementDate',
      headerName: 'Enforcement Date',
      minWidth: 208,
      flex: 1,
      cellClassName: removedCellClassName,
      valueGetter: (params: GridValueGetterParams) => params.row.lastEnforcementDate,
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center" gap="40px">
          <p>{dayjs(row.lastEnforcementDate).format(DATE_FORMAT)}</p>
          {handleCheckSelectedRow(row.id) && (
            <Box display="flex" alignItems="center" gap="14px">
              <EditIcon
                sx={{
                  ...defaultStyleIcon,
                }}
                onClick={() => handleClickEditSingleRow(row)}
                cursor="pointer"
                width={17}
                height={17}
              />
              <HistoryIcon
                sx={{
                  ...defaultStyleIcon,
                }}
                cursor="pointer"
                width={15}
                height={17}
              />
              <DeleteIcon
                sx={{
                  ...defaultStyleIcon,
                }}
                cursor="pointer"
                width={15}
                height={15}
              />
            </Box>
          )}
        </Box>
      ),
    },
  ];
};

const sxButtonConfirmModal = {
  minWidth: '116px',
  borderRadius: '10px',
  lineHeight: '20px',
  paddingY: '10px',
  height: 40,
};

const SocialMediaList = () => {
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [listSelectedRow, setListSelectedRow] = useState<SocialMediaLink[]>([]);
  const [rowEditing, setRowEditing] = useState<SocialMediaLink | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<EditSocialMediaLinkInput>({
    resolver: yupResolver(editSocialMediaLinkInputSchema),
    mode: 'onChange',
  });
  const { assetId } = useParams();

  const deleteSocialMediaLinksMutation = useDeleteSocialMediaLinks({
    mutationConfig: {
      onSuccess: () => {
        successMessage('Social Media Link has been deleted!');
        clearData();
      },
      onError: () => {
        errorMessage('Failed to delete Social Media Link');
      },
    },
  });

  const handleDeleteSocialLink = useCallback(async () => {
    if (!selectedRow || !selectedRow.length || !assetId) return;
    const payload = {
      socialMediaLinkIds: [...selectedRow],
      modelId: assetId,
    };
    await deleteSocialMediaLinksMutation.mutateAsync(payload);
  }, [deleteSocialMediaLinksMutation, selectedRow, assetId]);

  const handleOnSubmit = useCallback(async () => {
    if (isDeleting) {
      await handleDeleteSocialLink();
      return;
    }
    if (!rowEditing) return;
    setRowEditing(null);
    reset();
  }, [rowEditing, reset, isDeleting, handleDeleteSocialLink]);

  const [open, onOpen, onCancel, onSubmit] = useConfirmationModal(false, handleOnSubmit);

  const handleCheckSelectedRow = useCallback(
    (id: string) => {
      return selectedRow.filter((ele) => ele === id).length > 0;
    },
    [selectedRow],
  );

  const handleClickEditSingleRow = useCallback(
    (row: SocialMediaLink) => {
      if (!rowEditing) {
        setRowEditing(row);
      }
    },
    [rowEditing],
  );

  const editSocialMediaLinksMutation = useEditSocialMediaLinks({
    mutationConfig: {
      onSuccess: () => {
        successMessage('Social Media Link has been edited!');
        clearData();
      },
      onError: () => {
        errorMessage('Failed to edit Social Media Link');
      },
    },
  });

  const handleClickSave = () => {
    if (!rowEditing || Object.keys(errors).length !== 0) return;
    if (!assetId) {
      errorMessage('Model could not be found');
      return;
    }
    const { lastEnforcementDate, id } = rowEditing;
    const payload = {
      ...getValues(),
      lastEnforcementDate: lastEnforcementDate,
    };
    editSocialMediaLinksMutation.mutate({ data: payload, modelId: assetId, id });
  };

  const handleClickCancel = () => {
    if (!rowEditing) return;
    onOpen();
  };

  const handleClickDelete = () => {
    onOpen();
    setIsDeleting(true);
  };

  const clearData = useCallback(() => {
    setRowEditing(null);
    reset();
    if (isDeleting) setIsDeleting(false);
  }, [reset, isDeleting]);

  const columns: GridColDef<SocialMediaLink>[] = useMemo(
    () =>
      getColumns({
        categoryOptions,
        control,
        errors,
        idEditing: rowEditing?.id,
        handleCheckSelectedRow,
        handleClickEditSingleRow,
      }),
    [control, errors, handleCheckSelectedRow, handleClickEditSingleRow, rowEditing?.id],
  );

  const getSelectedMultipleValue = useCallback(
    (items: SocialMediaLink[], selectedItemsId: string[]) => {
      const selectedItems = items.filter((item) =>
        selectedItemsId.includes((item as { id: string })?.id),
      );
      if (!isEqual(listSelectedRow, selectedItems)) {
        setListSelectedRow(selectedItems);
      }
      const uniqCategories = selectedItems.reduce(
        (statuses: string[], { category }) =>
          statuses.includes(category) ? statuses : [...statuses, category],
        [],
      );
      const category = uniqCategories.length > 1 ? CATEGORY_MIXED : uniqCategories[0];

      return { status: category };
    },
    [listSelectedRow],
  );

  const getSelectedValue = <T extends { id: string }>(_items: T[], selectedItemsId: string[]) => {
    setSelectedRow(selectedItemsId);
    return '';
  };

  const handleCancelConfirmModal = () => {
    onCancel();
    if (isDeleting) {
      setTimeout(() => {
        setIsDeleting(false);
      }, 250);
    }
  };

  return (
    <>
      <Table<SocialMediaLink>
        searchPlaceholder="Search for (incl Regex)"
        queryName="social-media-links"
        // apiRoute={`/admin/models/d4a2bc12-441c-4741-bea0-f437b1cdae6b/social-media-links`}
        apiRoute={`/admin/models/${assetId}/social-media-links`}
        columns={columns}
        filtersList={FILTERS_LIST}
        getSelectedMultipleValue={getSelectedMultipleValue}
        getSelectedValue={getSelectedValue}
        selectedRowType={TableSelectType.SocialMediaLink}
        checkboxSelection
        showFilterBySelect
        filterBySelectOptions={options}
        renderAdditionalControl={
          <Grid
            container
            sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', justifyContent: 'flex-end' }}
          >
            {/* <EnforceButton />
          <ScreenshotButton /> */}
            <AddButton />
            <UploadButton />
            <EditButton listEdit={listSelectedRow} idSelected={selectedRow} />
          </Grid>
        }
        customSxForTable={{
          '& .Mui-selected': {
            backgroundColor: '#0000000D !important',
          },
        }}
      />
      <Box display="flex" justifyContent="end" gap="14px">
        {renderInteractButtonList({ handleClickSave, handleClickCancel, handleClickDelete }).map(
          ({ text, sx, variant, handleClick }) => (
            <InteractButton
              onClick={handleClick}
              key={text}
              sx={sx}
              text={text}
              variant={variant as 'text' | 'outlined' | 'contained'}
              disabled={editSocialMediaLinksMutation.isLoading}
            />
          ),
        )}
      </Box>
      <ConfirmModal
        open={open}
        onCancel={handleCancelConfirmModal}
        onSubmit={onSubmit}
        icon={isDeleting ? <DeleteModalIcon width={55} height={71} /> : undefined}
        title={
          <Typography
            sx={{
              fontSize: '24px',
              lineHeight: '20px',
              color: '#464F6080',
              whiteSpace: 'normal',
              marginBottom: '-8px',
            }}
          >
            {isDeleting
              ? 'Are you sure you want to delete this item?'
              : 'If you exit the edit mode, your current changes will be discarded.'}
          </Typography>
        }
        submitText="OK"
        usingModalV2
        sxButtonCancel={{ ...sxButtonConfirmModal }}
        submitButton={
          isDeleting ? (
            <LoadingButton
              sx={{ ...sxButtonConfirmModal }}
              size="small"
              variant="contained"
              color="error"
              onClick={onSubmit}
              loading={deleteSocialMediaLinksMutation.isLoading}
            >
              Delete
            </LoadingButton>
          ) : undefined
        }
      />
    </>
  );
};
export default React.memo(SocialMediaList);
