import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const CloseIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path
      d="M0.501803 -2.47955e-05C0.373598 -2.47955e-05 0.245393 0.0480518 0.147236 0.146209C-0.0490785 0.342524 -0.0490785 0.659029 0.147236 0.855343L9.14563 9.85374C9.34195 10.0501 9.65845 10.0501 9.85477 9.85374C10.0511 9.65743 10.0511 9.34092 9.85477 9.14461L0.85637 0.146209C0.758213 0.0480518 0.630008 -2.47955e-05 0.501803 -2.47955e-05Z"
      fill="#A3A7B0"
    />
    <path
      d="M9.50065 -0.000475883C9.37245 -0.000475883 9.24424 0.0476007 9.14608 0.145759L0.147686 9.14416C-0.0466246 9.34047 -0.0466246 9.65698 0.147686 9.85329C0.344 10.0476 0.660507 10.0476 0.856821 9.85329L9.85522 0.854893C10.0515 0.658578 10.0515 0.342072 9.85522 0.145759C9.75706 0.0476007 9.62886 -0.000475883 9.50065 -0.000475883Z"
      fill="#A3A7B0"
    />
  </BaseIcon>
);
