import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const ArrowTopRighticon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path
      d="M7 6.98166H8V3.98166H5V4.98166H6.2929L3.62811 7.64644L4.33522 8.35354L7 5.68876V6.98166Z"
      fill="#44AEFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 9.5C11.5 10.6046 10.6046 11.5 9.5 11.5H2.5C1.39543 11.5 0.5 10.6046 0.5 9.5V2.5C0.5 1.39543 1.39543 0.5 2.5 0.5H9.5C10.6046 0.5 11.5 1.39543 11.5 2.5V9.5ZM9.5 10.5H2.5C1.94772 10.5 1.5 10.0523 1.5 9.5V2.5C1.5 1.94772 1.94772 1.5 2.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5Z"
      fill="#44AEFB"
    />
  </BaseIcon>
);
