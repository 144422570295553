import { ArrowTopRighticon } from '@atoms/Icons/ArrowTopRightIcon';
import { FileDocumentIcon } from '@atoms/Icons/FileDocumentIcon';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSocialUserInfo } from '../api/get-social-user-info';
import { Button } from '@atoms';
import { UserIcon } from '@atoms/Icons/UserIcon';
import DataSocialMediaUpdateModal from './data-social-media-update-modal';

const SocialUserInfo = () => {
  const { id } = useParams();
  const { data, isLoading } = useSocialUserInfo({ id: id! });
  const [isOpenDataSocial, setIsOpenDataSocial] = useState(false);

  const handleOnClickIconOpenLink = () => {
    if (data && data.transparencyReportLink) {
      window.open(data.transparencyReportLink, '_blank');
    }
  };

  const handleClickOfficialSocialMedia = () => {
    setIsOpenDataSocial(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2.5,
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: '66px',
        marginLeft: '27px',
      }}
    >
      <Box sx={{ display: 'flex', gap: 0.25, flexWrap: 'wrap', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap', alignItems: 'center' }}>
          <FileDocumentIcon width={18} height={20} />
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.primary">
              Transparency Report:{' '}
            </Typography>
            {isLoading ? (
              <CircularProgress
                sx={{
                  width: '10px !important',
                  height: '10px !important',
                  marginLeft: '10px',
                }}
              />
            ) : (
              <Box display="flex" alignItems="center" gap={1.25}>
                <Typography component="span" variant="body2" color="text.primary" fontWeight={600}>
                  &nbsp;{data?.firstName} {data?.lastName}
                </Typography>
                <IconButton
                  onClick={handleOnClickIconOpenLink}
                  size="small"
                  sx={{ width: '16px', height: '16px' }}
                >
                  <ArrowTopRighticon width={12} height={12} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="black"
          onClick={handleClickOfficialSocialMedia}
          sx={{
            width: 'fit-content',
            paddingX: '10px',
            borderRadius: '10px',
            marginTop: '8px',
            marginBottom: '12px',
            '& .MuiSvgIcon-root': {
              fill: '#fff',
            },
          }}
        >
          <Box component="div" display="flex" gap={1.25}>
            <UserIcon width={24} height={24} />
            <Typography component="span" color="text.primary" variant="body2">
              Official Social Media
            </Typography>
          </Box>
        </Button>
        <DataSocialMediaUpdateModal
          isOpenDataSocial={isOpenDataSocial}
          listData={data?.socialMedia || []}
          onClose={() => setIsOpenDataSocial((prev) => !prev)}
        />
      </Box>
    </Box>
  );
};
export default React.memo(SocialUserInfo);
