import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';

import { Button, Modal, TextField } from '@atoms';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, Divider, Typography } from '@mui/material';

import { errorMessage, successMessage } from '@services';
import { getSchemaEditContract } from '@constants';

import { Axios } from '@helpers';
import { splitLinks } from '../SubmitLinksModal/utils';

interface FormData {
  firstName: string;
  lastName: string;
  accounts: string;
  updateProfile: boolean;
}

interface IProps {
  submissionId: string;
  onClose: () => void;
  open: boolean;
  setTriggerRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditEsignModal: FC<IProps> = ({ submissionId, open, onClose, setTriggerRefetch }) => {
  const { mutate: onUpdateEsign, isLoading } = useMutation<void, unknown, FormData>(
    (formData: FormData) => {
      const links = formData.accounts?.trim() ? splitLinks(formData.accounts) : undefined;
      return Axios.put(`/admin/contracts/submissions/${submissionId}`, {
        ...formData,
        accounts: links,
      });
    },
    {
      onSuccess: () => {
        successMessage('Contract submission has been updated!');
        setTriggerRefetch((val) => !val);
        onCloseAndReset();
      },
      onError: (error: any) => {
        const message = error?.response?.data?.message;
        errorMessage(message);
      },
    },
  );

  const { handleSubmit, control, reset, formState } = useForm<FormData>({
    resolver: yupResolver(getSchemaEditContract),
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      accounts: '',
      updateProfile: true,
    },
    delayError: 400,
  });

  const onCloseAndReset = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: FormData) => onUpdateEsign(data);

  return (
    <Modal title="Update Submission" onClose={onCloseAndReset} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column">
          <Divider sx={{ my: '24px', mx: '-32px' }} />
          <Box display="flex" flexWrap="wrap" gap="12px" sx={{ mb: '24px' }}>
            <TextField
              control={control}
              name="firstName"
              label="First Name"
              placeholder="First user name"
              required
            />
          </Box>
          <Box display="flex" flexWrap="wrap" gap="12px" sx={{ mb: '24px' }}>
            <TextField
              control={control}
              name="lastName"
              label="Last Name"
              placeholder="Last user name"
              required
            />
          </Box>

          <Box display="flex" flexWrap="wrap" gap="12px" sx={{ mb: '24px' }}>
            <TextField
              control={control}
              name="accounts"
              label="Social Accounts"
              placeholder="Social accounts"
              rows={7}
              multiline
            />
          </Box>

          <Box display="flex" flexWrap="wrap" gap="12px" sx={{ mb: '24px' }}>
            <Controller
              name="updateProfile"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Also update in the account details page"
                />
              )}
            />
            <Typography textAlign="left" color="text.primary" fontSize="14px" variant="body2">
              Note: Upon clicking Save, this submission will be archived. A new submission will be
              created, and the user will be notified via email and in-app notifications. The user
              will also need to re-sign.
            </Typography>
          </Box>
          <Box display="flex" ml="auto">
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '100px',
              }}
              onClick={onCloseAndReset}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
              loading={isLoading}
              sx={{ marginLeft: '20px', width: '100px' }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default EditEsignModal;
