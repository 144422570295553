import QueryKeys from '@constants/query-keys';
import { Axios as api } from '@helpers';
import { CCModelDetailsType } from '@services/types';
import { useQuery, UseQueryOptions } from 'react-query';

// Define response type based on CCBaseDetailsType which contains user info fields
export type SocialUserInfoResponse = CCModelDetailsType;

export const getSocialUserInfo = async (id: string): Promise<SocialUserInfoResponse> => {
  const response = await api.get<SocialUserInfoResponse>(`/admin/accounts/content-creator/${id}`);
  return response.data;
};

export const getSocialUserInfoQueryOptions = (
  id: string,
): UseQueryOptions<SocialUserInfoResponse> => {
  return {
    queryKey: [QueryKeys.socialUserInfo, id],
    queryFn: () => getSocialUserInfo(id),
  };
};

type UseUsersOptions = {
  id: string;
  queryConfig?: UseQueryOptions<SocialUserInfoResponse>;
};

export const useSocialUserInfo = ({ id, queryConfig }: UseUsersOptions) => {
  return useQuery({
    ...getSocialUserInfoQueryOptions(id),
    ...queryConfig,
  });
};
