import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import styled from 'styled-components';

import { PAYMENT_FILTER_STATUS } from '@constants';
import { FilterCloseWrap } from './FilterCloseWrap';
import { CreatorPaymentStatus } from '@services';

const SelectStyled = styled(Select)`
  height: 50px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const MenuItemStyled = styled(MenuItem)`
  align-items: center;
  display: flex;

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

interface Props extends SelectProps {
  onClose?: () => void;
  label?: string;
  disabledStatus?: CreatorPaymentStatus[];
}

export const StatusFilter: FC<Props> = ({
  onClose,
  label = 'Status',
  disabledStatus = [],
  ...props
}) => (
  <FilterCloseWrap label={label} onClose={onClose}>
    <SelectStyled placeholder="Select option" {...props}>
      {Object.keys(PAYMENT_FILTER_STATUS)
        ?.filter((x) => !disabledStatus.includes(x as CreatorPaymentStatus))
        .map((status) => (
          <MenuItemStyled key={status} value={status}>
            {PAYMENT_FILTER_STATUS[status].icon}
            {PAYMENT_FILTER_STATUS[status].label}
          </MenuItemStyled>
        ))}
    </SelectStyled>
  </FilterCloseWrap>
);
