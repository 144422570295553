import { FC } from 'react';

import { BaseIcon } from '../BaseIcon';
import { SvgIconProps } from '@mui/material';

export const FileDocumentIcon: FC<SvgIconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M4 16H14V14H4V16Z" fill="black" />
    <path d="M14 12H4V10H14V12Z" fill="black" />
    <path d="M4 8H8V6H4V8Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V7C18 3.13401 14.866 0 11 0H3ZM3 2H10V7H16V17C16 17.5523 15.5523 18 15 18H3C2.44772 18 2 17.5523 2 17V3C2 2.44772 2.44772 2 3 2ZM12 2.10002C13.6113 2.4271 14.9413 3.52906 15.584 5H12V2.10002Z"
      fill="black"
    />
  </BaseIcon>
);
