import { Autocomplete, FormControl, SxProps, TextField } from '@mui/material';
import { Control, Controller, FieldError, FieldPath, PathValue } from 'react-hook-form';
import { EditSocialMediaLinkInput } from '../api/edit-social-links';
import { EditMultipleSocialMediaLinkInput } from '../api/edit-multiple-social-links';
import { CreateSocialMediaLinkInput } from '../api/create-social-links';

type TControlDropdown =
  | EditSocialMediaLinkInput
  | EditMultipleSocialMediaLinkInput
  | CreateSocialMediaLinkInput;

interface IDropdownControllerProps<T extends TControlDropdown> {
  sx: SxProps;
  name: FieldPath<T>;
  control: Control<T>;
  defaultValue?: PathValue<T, FieldPath<T>>;
  options: string[];
  errors: FieldError | undefined;
  heplerText?: string;
  getOptionLabel: ((option: string) => string) | undefined;
}

const DropdownController = <T extends TControlDropdown>({
  sx,
  name,
  control,
  defaultValue,
  options,
  errors,
  heplerText,
  getOptionLabel,
}: IDropdownControllerProps<T>) => {
  return (
    <FormControl fullWidth sx={{ ...sx }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={(_, value) => field.onChange(value)}
            renderInput={(params) => (
              <TextField error={!!errors} helperText={heplerText ? heplerText : null} {...params} />
            )}
          />
        )}
      />
    </FormControl>
  );
};

export default DropdownController;
