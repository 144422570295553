export enum ROUTES {
  Home = '/',
  Login = 'login',
  ForgotPassword = 'forgot-password',
  Users = 'users',
  Links = 'links',
  Accounts = 'accounts',
  ContentCreators = 'content-creators',
  BusinessAccounts = 'business-accounts',
  AssetAccount = 'asset-account',
  AssetDetails = 'asset-details',
  ModelDetails = 'model-details',
  Signup = 'signup',
  CreateNewPassword = 'create-new-password',
  ChangePassword = 'change-password',
  Settings = 'settings',
  AccountDetails = 'account-details',
  LinksManagement = 'links-management',
  SocialMedia = 'social-media',
  Workspace = 'workspace',
  Billing = 'billing',
  NewClients = 'new-clients',
  SocialAuth = 'social-auth',
  Assign = 'assign',
  ScanManagement = 'scan-management',
  ScanSettings = 'scan-settings',
  Details = 'details',
  Whitelist = 'whitelist',
  Add = 'add',
  Notifications = 'notifications',
  Notification = 'notification',
  WorkingMode = 'working-mode',
  Raw = 'raw',
  Infringing = 'infringing',
  FalsePositive = 'false-positive',
  Move = 'move-assets',
  UploadDocument = 'upload-documents',
  ESign = 'esign',
  ActionLog = 'action-logs',
  CopyrightRegistration = 'copyright-registration',
  History = 'history',
  SystemSettings = 'system-settings',
}

export const ROUTE_IS_READY = {
  ACTION_LOGS: true,
};
