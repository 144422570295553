import { Axios, MutationConfig } from '@helpers';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { InferType } from 'yup';
import { getSocialUserInfoQueryOptions } from './get-social-user-info';

export const editSocialMediaInputSchema = yup.object({
  fullLink: yup.string().nullable().required('Account URL is required'),
});

export type EditSocialMediaInput = InferType<typeof editSocialMediaInputSchema>;

export const editOfficialSocialMedia = ({
  data,
  modelId,
}: {
  data: EditSocialMediaInput;
  modelId: string;
}) => {
  return Axios.patch(`/admin/models/${modelId}/social-media-links`, data);
};

type UseEditOfficialSocialMediaOptions = {
  mutationConfig?: MutationConfig<typeof editOfficialSocialMedia>;
};

export const useEditOfficialSocialMedia = ({
  mutationConfig,
}: UseEditOfficialSocialMediaOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getSocialUserInfoQueryOptions(args[1].modelId).queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: editOfficialSocialMedia,
  });
};
