import { InfoCell } from '@atoms';
import { SocialAccountFilter, Table } from '@molecules';
import { Box, Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC, useCallback, useMemo } from 'react';

import { CopyrightContentStatus, CopyrightType } from '@services/types/copyright';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMAT, ROUTES } from '@constants';
import dayjs from 'dayjs';
import { removedCellClassName } from '@helpers';
import { renderPlanCell } from '@molecules/Table/cells';
import { TableSelectType } from '@services';
import { BillingFrequency } from '@atoms/BillingFrequency';

function getColumns(): GridColDef<CopyrightType>[] {
  function getStatus(status: string) {
    let color: 'default' | 'warning' | 'success' = 'default';
    switch (status) {
      case CopyrightContentStatus.Pending:
        status = 'pending';
        color = 'warning';
        break;
      case CopyrightContentStatus.Completed:
        status = 'completed';
        color = 'success';
        break;

      default:
        status = 'pending';
        color = 'warning';
        break;
    }

    return { status, color };
  }

  return [
    {
      field: 'company',
      headerName: 'Content creator',
      width: 300,
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const {
          company: { companyName, logo, email },
        } = row;
        return <InfoCell name={companyName || ''} email={email || ''} avatarUrl={logo || ''} />;
      },
    },
    {
      field: 'subscriptionPlan',
      headerName: 'Plan',
      minWidth: 60,
      flex: 1,
      filterable: false,
      sortable: false,
      cellClassName: removedCellClassName,
      renderCell: renderPlanCell,
    },
    {
      field: 'billingFrequency',
      headerName: 'Billing frequency',
      minWidth: 80,
      flex: 1,
      cellClassName: removedCellClassName,
      renderCell: ({ row: { billingFrequency } }) => {
        return <BillingFrequency billingFrequency={billingFrequency} />;
      },
    },
    {
      field: 'status',
      headerName: 'Certificate Status',
      flex: 1.3,
      minWidth: 100,
      renderCell: ({ row }) => {
        const data = getStatus(row.status || 'default');

        return <Chip color={data.color} label={data.status} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date of addition',
      flex: 1.2,
      minWidth: 120,
      renderCell: ({ row: { createdAt } }) => dayjs(createdAt).format(DATE_FORMAT),
    },
    {
      field: 'firstContentCreationYear',
      headerName: 'First Content Creation Year',
      flex: 1.6,
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryAddress',
      headerName: 'Delivery Address',
      flex: 2,
      minWidth: 170,
      filterable: false,
      sortable: false,
    },
    {
      field: 'accounts',
      headerName: 'Accounts',
      flex: 2,
      minWidth: 220,
      filterable: false,
      sortable: false,
      renderCell: ({ row: { accounts } }) => {
        const accountList = accounts.map((item) => ({ value: item, label: item }));
        return <SocialAccountFilter accountList={accountList} defaultValue={accounts[0]} />;
      },
    },
  ];
}

export const CopyrightPage: FC = () => {
  const navigate = useNavigate();
  const columns = useMemo(() => getColumns(), []);
  const onRowClick = useCallback(
    ({ companyId, company }: CopyrightType) => {
      navigate(
        `/${ROUTES.ContentCreators}/${companyId}/${company.modelId}/${ROUTES.UploadDocument}`,
      );
    },
    [navigate],
  );
  const getSelectedValue = (items: CopyrightType[], selectedItemsId: string[]) => {
    const formatSelectedId = selectedItemsId.flat();
    const selectedItems = items.filter((item) =>
      formatSelectedId.some((_id: string) => item.id === _id),
    );
    const uniqStatuses = selectedItems.reduce(
      (statuses: string[], { status }) =>
        statuses.includes(status) ? statuses : [...statuses, status],
      [],
    );
    const status = uniqStatuses.length > 1 ? 'mixed' : uniqStatuses[0];
    return status;
  };

  return (
    <>
      <Box mb="16px" display="flex" justifyContent="space-between">
        <Typography variant="h4">Copyright Registration</Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '32px 24px',
          marginTop: '12px',
          border: '1px solid #e0e0e0',
        }}
      >
        <Table<CopyrightType>
          queryName="/admin/copyright-contents"
          columns={columns}
          apiRoute="/admin/copyright-contents"
          refetchQueryName="/admin/copyright-contents"
          searchPlaceholder="Search for User name and Email"
          onRowClick={onRowClick}
          getSelectedValue={getSelectedValue}
          checkboxSelection
          selectedRowType={TableSelectType.Copyright}
          rowHeight={70}
        />
      </Box>
    </>
  );
};
