import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';

import { Button, Modal, TextField, UploadAvatar } from '@atoms';
import { LoadingButton } from '@mui/lab';
import { Box, Divider } from '@mui/material';

import { AssetsType, SocialMediaAccount, successMessage } from '@services';
import { assetsConfig, getSchemaAddAsset } from '@constants';

import { AssetDetails } from '@molecules';
import { Axios, queryClient } from '@helpers';
import { useParams } from 'react-router-dom';
import { defaultImageValues } from './utils';

type ImageState = {
  previewImg?: string | ArrayBuffer;
  formData: globalThis.FormData;
};

interface FormData {
  username: string;
  firstName: string | null;
  lastName: string | null;
  imageData: ImageState;
  properties: { [x: string]: string | null | string[] };
}

interface IProps {
  onClose: () => void;
  open: boolean;
  assetType: AssetsType;
}

const AddAssetModal: FC<IProps> = ({ open, onClose, assetType }) => {
  // get generic data for text fields depended on assetType
  const { id } = useParams();

  const {
    validationObjectSchema,
    fields,
    defaultValues: propertiesDefaultValues,
    placeholder,
  } = assetsConfig[assetType];

  const { mutate: onCreateNewAsset, isLoading } = useMutation<FormData, unknown, unknown>(
    (formData) => Axios.post(`/admin/companies/${id}/assets`, formData),
    {
      onSuccess: () => {
        successMessage('Asset has been added!');
        queryClient.refetchQueries(`business-account-models-${id}`);
        onCloseAndReset();
      },
    },
  );

  const { handleSubmit, control, reset, watch, formState, setValue } = useForm<FormData>({
    resolver: yupResolver(getSchemaAddAsset(validationObjectSchema)),
    mode: 'onChange',
    defaultValues: {
      username: '',
      imageData: defaultImageValues,
      properties: propertiesDefaultValues,
    },
    delayError: 400,
  });

  const imageData = watch('imageData');
  const isModelsType = assetType === AssetsType.Models;

  const onCloseAndReset = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: FormData) => {
    const formDataToRequest = new FormData();
    const { imageData: image } = data;
    const imageFile = image.formData.get('file');

    if (imageFile) {
      formDataToRequest.append('file', imageFile);
    }

    formDataToRequest.append('username', data.username);
    formDataToRequest.append('type', assetType);
    if (assetType === AssetsType.Models) {
      data.firstName && formDataToRequest.append('firstName', data.firstName);
      data.lastName && formDataToRequest.append('lastName', data.lastName);
    }

    if (isModelsType) {
      const socialMediaUrl =
        (data?.properties?.socialMedia as unknown as SocialMediaAccount[])
          .map((model) => model.redirectLink)
          .join(',') || '';
      formDataToRequest.append('plan', data.properties.plan as string);
      formDataToRequest.append('assetProperties', JSON.stringify({ socialMediaUrl }));
    } else {
      formDataToRequest.append('assetProperties', JSON.stringify(data.properties));
    }

    onCreateNewAsset(formDataToRequest);
  };

  return (
    <Modal title="Add asset" onClose={onCloseAndReset} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column">
          <Divider sx={{ my: '24px', mx: '-32px' }} />

          <Box display="flex" flexWrap="wrap" gap="12px">
            <UploadAvatar
              src={imageData.previewImg}
              onUpload={(previewImg, formData) => setValue('imageData', { previewImg, formData })}
              sx={{ m: '0 auto' }}
            />
            <TextField
              control={control}
              name="username"
              label={placeholder}
              placeholder={placeholder}
              required
            />
            {assetType === AssetsType.Models && (
              <Box display="flex" width="100%" gap="24px" mb="24px" mt="24px">
                <TextField
                  label="First name"
                  name="firstName"
                  placeholder="First name"
                  control={control}
                />
                <TextField
                  label="Last name"
                  name="lastName"
                  placeholder="Last name"
                  control={control}
                />
              </Box>
            )}

            <AssetDetails fields={fields} control={control} />
          </Box>
          <Box display="flex" ml="auto" mt="24px">
            <Button variant="text" color="secondary" onClick={onCloseAndReset}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="black"
              type="submit"
              disabled={!formState.isValid}
              loading={isLoading}
            >
              Add asset
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default AddAssetModal;
