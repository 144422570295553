import { FC, SyntheticEvent, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { NotificationSettingsTable } from '@organisms';

enum TabValue {
  Notifications,
}

const tabList = [{ label: 'Notifications', value: TabValue.Notifications, filter: null }];

interface Props {
  title: string;
}

export const SystemSettings: FC<Props> = ({ title }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case TabValue.Notifications:
        return <NotificationSettingsTable />;
      default:
        return <NotificationSettingsTable />;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box mt="20px">
        <Box display="flex" position="relative">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="nav tabs example"
            sx={{ width: '100%' }}
          >
            {tabList.map(({ label, value }) => (
              <Tab label={label} key={value} />
            ))}
          </Tabs>
        </Box>
      </Box>
      {renderTabContent()}
    </>
  );
};
