import { FC, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import BlockIcon from '@mui/icons-material/Block';

import { Axios, onBackButtonClick, queryClient } from '@helpers';
import { useConfirmationModal, useFormEqual } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CREATOR_PLANS_OPTIONS, getAssetAccountValidation } from '@constants';
import { AssetDetailsType, AssetsType, CompanyType, successMessage } from '@services';

import { Avatar, Box, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AssetDetails, SelectFabric } from '@molecules';
import { InputWithChips, TextField } from '@atoms';
import { ConfirmModal } from '@organisms/Modals';
import { convertSocialMedia, getConfig } from './utils';

interface FormData {
  username: string;
  firstName: string | null;
  lastName: string | null;
  transparencyReportLink?: string | null;
  plan?: string | null;
  properties: { [x: string]: any };
}

export const AssetAccountDetails: FC = () => {
  const [disabled, setDisabled] = useState(true);
  const { id, type, assetId } = useParams();
  const isBusiness = type === CompanyType.Business;

  const { data } = useQuery<unknown, unknown, AssetDetailsType>(`asset-account-details/${id}`, () =>
    Axios.get(`/admin/accounts/${id}/models/${assetId}`).then((res) => res.data),
  );
  const {
    username,
    photoUrl,
    transparencyReportLink,
    plan,
    assetType,
    properties,
    lastName,
    firstName,
  } = (data as AssetDetailsType) || {};
  const config = useMemo(() => getConfig(isBusiness), [isBusiness]);
  const { fields } = config[assetType];
  const isModel = data?.assetType === AssetsType.Models;

  const defaultValues = useMemo(
    () => ({
      username,
      firstName,
      lastName,
      properties,
      plan,
      ...(isBusiness ? { transparencyReportLink: transparencyReportLink || '' } : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBusiness, username, firstName, lastName, transparencyReportLink, properties],
  );

  const { handleSubmit, control, reset, watch } = useForm<FormData>({
    resolver: yupResolver(getAssetAccountValidation()),
    defaultValues,
    mode: 'onChange',
    delayError: 700,
  });

  useFormEqual<FormData>({
    setDisabled,
    disabled,
    data: defaultValues,
    reset,
    watch,
  });

  const { mutate: onSubmit, isLoading } = useMutation<unknown, unknown, FormData>(
    (formData) => Axios.put(`/admin/accounts/${id}/models/${assetId}`, formData),
    {
      onSuccess: () => {
        successMessage(`${username} has been updated!`);
        queryClient.refetchQueries(`asset-account-details/${id}`);
      },
    },
  );

  const finalSubmit = (formData: FormData) => {
    if (!formData.firstName) formData.firstName = null;
    if (!formData.lastName) formData.lastName = null;
    onSubmit({
      ...formData,
      transparencyReportLink: formData.transparencyReportLink || null,
    });
  };
  const onInvalid = (errors: any) => console.error(errors);
  const { mutate: deleteAsset, isLoading: isLoadingDelete } = useMutation<
    unknown,
    unknown,
    unknown
  >(() => Axios.delete(`/admin/accounts/${id}/models/${data?.id}`), {
    onSuccess: () => {
      onBackButtonClick();
      successMessage(`${username} has been removed!`);
    },
  });

  const [open, onOpen, onCancel, onSubmitDelete] = useConfirmationModal(false, deleteAsset);

  return (
    <Box component="form" onSubmit={handleSubmit(finalSubmit, onInvalid)}>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Typography variant="h5" mb="8px">
          General information
        </Typography>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={disabled}
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          Save changes
        </LoadingButton>
      </Box>
      <Box width="664px">
        <Typography variant="body2" color="text.secondary" mb="16px">
          Below is general information about the user related links.
        </Typography>
        <Box display="flex" gap="24px" mb="36px" mt="24px">
          <Avatar alt={username} sx={{ width: 82, height: 82 }} src={photoUrl}>
            {username[0].toUpperCase()}
          </Avatar>
          <TextField
            label={isModel ? 'Username' : 'Asset name'}
            control={control}
            name="username"
            required
          />
        </Box>
        {assetType === AssetsType.Models && (
          <Box display="flex" gap="24px" mb="24px" mt="24px">
            <TextField
              label="First name"
              name="firstName"
              placeholder="First name"
              control={control}
            />
            <TextField
              label="Last name"
              name="lastName"
              placeholder="Last name"
              control={control}
            />
          </Box>
        )}
        {isBusiness && (
          <TextField
            label="Transparency link report"
            control={control}
            name="transparencyReportLink"
            placeholder="Add link"
          />
        )}
      </Box>
      {assetType !== AssetsType.Models && (
        <Box width="664px">
          <Typography variant="h5" mt="36px" mb="16px">
            Asset details
          </Typography>
          <Box display="flex" flexWrap="wrap" gap="12px">
            <AssetDetails fields={fields} control={control} />
          </Box>
        </Box>
      )}
      <Divider sx={{ my: '24px' }} />
      <InputWithChips
        label="Usernames"
        mt="24px"
        chips={convertSocialMedia(data?.properties?.socialMedia)}
      />
      <Divider sx={{ my: '24px' }} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" mb="8px">
            Delete asset
          </Typography>
          <Typography variant="body2" color="secondary">
            If you delete the asset you won&apos;t be able to track the progress anymore.
          </Typography>
        </Box>
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={onOpen}
          startIcon={<BlockIcon />}
          loading={isLoadingDelete}
        >
          Delete asset
        </LoadingButton>
      </Box>
      <Divider sx={{ my: '24px' }} />
      <Typography variant="h5" mb="8px">
        Billing plan
      </Typography>
      <Typography variant="body2" mb="16px">
        Here is you can find all information about users&apos; billing plans and plan due dates.
      </Typography>
      <Box display="flex" gap="24px" mt="24px">
        <SelectFabric
          label="Plan type"
          sx={{ maxWidth: '200px' }}
          control={control}
          name="plan"
          options={CREATOR_PLANS_OPTIONS}
          disabled
        />
      </Box>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmitDelete}
        title={`Are you sure you want to delete\n${username}?`}
        submitText="Yes, delete"
        submitColor="error"
      />
    </Box>
  );
};
