import { FC, ReactNode } from 'react';

import { Button, Modal } from '@atoms';
import { Box, SxProps, Typography } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import ModalV2 from '@atoms/ModalV2';

const DEFAULT_TITLE = 'Are you sure?';
const DEFAULT_CANCEL_TEXT = 'Cancel';
const DEFAULT_SUBMIT_TEXT = 'Yes';

interface ConfirmModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  open: boolean;
  title?: ReactNode;
  helperText?: ReactNode;
  cancelText?: string;
  submitText?: string;
  submitColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  icon?: ReactNode;
  usingModalV2?: boolean;
  sxButtonSubmit?: SxProps;
  sxButtonCancel?: SxProps;
  submitButton?: ReactNode;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  onCancel,
  onSubmit,
  open,
  title = DEFAULT_TITLE,
  helperText,
  submitColor,
  cancelText = DEFAULT_CANCEL_TEXT,
  submitText = DEFAULT_SUBMIT_TEXT,
  icon = <ReportIcon color="error" sx={{ fontSize: '100px' }} />,
  usingModalV2,
  sxButtonSubmit,
  sxButtonCancel,
  submitButton,
}) => {
  const renderContentInsideModal = () => {
    return (
      <Box margin="auto" display="flex" alignItems="center" flexDirection="column" width="100%">
        {icon}
        {title && (
          <Typography mt="20px" variant="body1" textAlign="center" sx={{ whiteSpace: 'pre' }}>
            {title}
          </Typography>
        )}
        {helperText}
        <Box display="flex" pb="9px" mt="48px" gap="24px">
          <Button
            onClick={onCancel}
            color="secondary"
            sx={{ minWidth: '148px', ...sxButtonCancel }}
          >
            {cancelText}
          </Button>
          {submitButton ? (
            submitButton
          ) : (
            <Button
              onClick={onSubmit}
              variant="contained"
              color={submitColor}
              sx={{ minWidth: '148px', ...sxButtonSubmit }}
            >
              {submitText}
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {usingModalV2 ? (
        <ModalV2 open={open} onClose={onCancel}>
          {renderContentInsideModal()}
        </ModalV2>
      ) : (
        <Modal open={open} onClose={onCancel}>
          {renderContentInsideModal()}
        </Modal>
      )}
    </>
  );
};
