import { FC, useCallback, useMemo, useState } from 'react';
import { Add } from '@mui/icons-material';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from '@constants';
import { useSubmitButtonState } from '@contexts/SubmitButton';
import { Axios, onSubmitButtonClick } from '@helpers';
import { useConfirmationModal, useModalState } from '@hooks';
import { AddAssetModal, ConfirmModal } from '@organisms';
import { AssetsType, BusinessDetailsType, successMessage } from '@services';

import { AccountsTable } from './AccountsTable';
import { BusinessAccountsDetailsForm } from './BusinessAccountsDetailsForm';
import { BusinessBillingPlanForm } from './BusinessBillingPlanForm';
import { Slots } from './Slots';
import { transformBusinessData } from './utils';

export const BusinessAccountsDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { disabled, loading } = useSubmitButtonState();
  const [isOpen, , onOpen, onClose] = useModalState(false);

  const { data, refetch } = useQuery<unknown, unknown, BusinessDetailsType>(
    `business-details/${id}`,
    () => Axios.get(`/admin/accounts/${id}`).then((res) => res.data),
  );

  const { mutate: blockAccountMutation } = useMutation<unknown, unknown, { isBlocked: boolean }>(
    (requestBody) => Axios.put(`/admin/accounts/${id}`, requestBody),
    {
      onSuccess: () => {
        setIsBlockingAccount(false);
        onCloseConfirmBlockModal();
        refetch();
        successMessage(`${data?.companyName} has been ${isBlocked ? 'enabled' : 'blocked'}!`);
      },
      onError: (error: any) => {
        setIsBlockingAccount(false);
        onCloseConfirmBlockModal();
        console.error(error);
      },
    },
  );

  const handleBlockAccount = () => {
    setIsBlockingAccount(true);
    blockAccountMutation({ isBlocked: !isBlocked });
  };

  const [
    isOpenConfirmBlockModal,
    onOpenConfirmBlockModal,
    onCloseConfirmBlockModal,
    onBlockAccount,
  ] = useConfirmationModal(false, () => handleBlockAccount());

  const [isBlockingAccount, setIsBlockingAccount] = useState<boolean>(false);

  const companyInfo = useMemo(() => data && transformBusinessData(data), [data]);
  const isBlocked = data?.isBlocked || false;

  const onMoveAssets = useCallback(() => {
    sessionStorage.setItem(
      'move-assets-close-path',
      `${window.location.pathname || ''}${window.location.search || ''}`,
    );
    navigate(`${ROUTES.Move}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb="16px" display="flex" justifyContent="space-between" alignItems="end">
        <Typography variant="h5" mb="8px">
          General information
        </Typography>
        <Box display="flex" gap="10px">
          <LoadingButton
            variant="contained"
            color={isBlocked ? 'success' : 'error'}
            startIcon={isBlocked ? null : <DoNotDisturbIcon />}
            onClick={onOpenConfirmBlockModal}
            loading={isBlockingAccount}
          >
            {isBlocked ? 'Enable' : 'Block'} Link Upload
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={onSubmitButtonClick}
            disabled={disabled}
            startIcon={<SaveIcon />}
            loading={loading}
          >
            Save changes
          </LoadingButton>
        </Box>
      </Box>
      <Typography mb="16px" variant="body2" color="text.secondary">
        Below is general information about the user and their account.
      </Typography>
      {companyInfo && <BusinessAccountsDetailsForm data={companyInfo} />}
      {companyInfo && <BusinessBillingPlanForm data={companyInfo} refetch={refetch} />}
      <Box mb="16px" display="flex" justifyContent="space-between" alignItems="end">
        <Box width="664px">
          <Typography variant="h5" mt="40px" mb="8px">
            Slots
          </Typography>
          <Typography mb="16px" variant="body2" color="text.secondary">
            Here is you can find the information about all and available slots and list of users
            connected to slots.
          </Typography>
          {companyInfo && <Slots {...companyInfo.slots} />}
        </Box>
        <Box display="flex" gap={3}>
          <Button variant="contained" onClick={onOpen} startIcon={<Add />}>
            Add asset
          </Button>
          <Button variant="contained" onClick={onMoveAssets} startIcon={<DriveFileMoveIcon />}>
            Move assets
          </Button>
        </Box>
      </Box>
      <AccountsTable />
      <Outlet />
      <AddAssetModal
        open={isOpen}
        onClose={onClose}
        assetType={companyInfo?.assetType || AssetsType.Models}
      />
      <ConfirmModal
        open={isOpenConfirmBlockModal}
        onCancel={onCloseConfirmBlockModal}
        onSubmit={onBlockAccount}
        title={`Are you sure you want to ${isBlocked ? 'enable' : 'block'} this account?`}
        submitText={`Yes, ${isBlocked ? 'enable' : 'block'}`}
      />
    </>
  );
};
