import { BillingFrequencyEnum } from '@services';

type Company = {
  assetType: string;
  companyName: string;
  companyType: string;
  email: string;
  isRemoved: boolean;
  joinDate: string;
  lastModified: string;
  id: string;
  logo: string | null;
  modelId: string;
  plan: string;
  status: string;
};
export interface CopyrightType {
  id: string;
  companyId: string;
  company: Company;
  legalName: string;
  creatorName: string;
  deliveryAddress: string;
  billingFrequency: BillingFrequencyEnum | null;
  accounts: string[];
  firstContentCreationYear: number;
  subscriptionPlan: string;
  price: number;
  status: string;
  createdAt: string;
  updatedAt: string;
}
export enum CopyrightContentStatus {
  Pending = 'pending',
  Completed = 'completed',
}
